import { apiInstanceConfig } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const editarConfiguracoes = async (payload) => {
    const { data } = await apiInstanceConfig.put(ENDPOINTS.EDITAR_CONFIG, payload);
    return data
}

export const exibirConfiguracoes = async () => {
    const { data } = await apiInstanceConfig.get(ENDPOINTS.EXIBIR_CONFIG);
    return data
}