<template>
  <v-card elevation="1" class="pa-6">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-expansion-panels focusable>
        <v-expansion-panel>
          <v-expansion-panel-header>
            E-mails Admin Master
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea
              class="pt-6"
              outlined
              label="E-mails Admin Master"
              v-model="formData.EMAILS_ADMIN_MASTER"
              :clearable="true"
              rows="3"
              required
              :rules="rules.genericRules"
            ></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Setores - Informações Científicas
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea
              class="pt-6"
              outlined
              label="Setores para a mesa de Informações Científicas"
              v-model="formData.SETORES_MESA_IC"
              :clearable="true"
              rows="3"
              required
              :rules="rules.genericRules"
            ></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Setores - Treinamentos
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea
              class="pt-6"
              outlined
              label="Setores para a mesa de Treinamentos"
              v-model="formData.SETORES_MESA_TREINAMENTO"
              :clearable="true"
              rows="3"
              required
              :rules="rules.genericRules"
            ></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Setores - Assessores Médicos
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea
              class="pt-6"
              outlined
              label="Setores para Assessores Médicos"
              v-model="formData.SETORES_APROVADORES"
              :clearable="true"
              rows="3"
              required
              :rules="rules.genericRules"
            ></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Tempos de tonalidade de Status
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea
              class="pt-6"
              outlined
              label="Definir tempos para mudança da tonalidade do status da solicitação"
              v-model="formData.SISTEMA_TEMPO_STATUS"
              :clearable="true"
              rows="3"
              required
              :rules="rules.genericRules"
            ></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
    <v-card-actions class="pt-4 pl-0 pb-0">
      <v-btn depressed color="fmq_gray" dark @click="send" :loading="loadingBtn"
        >Salvar Informações</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  nome: "ConfiguracoesPainel",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    formData: {
      EMAILS_ADMIN_MASTER: null,
      SETORES_MESA_IC: null,
      SETORES_MESA_TREINAMENTO: null,
      SETORES_APROVADORES: null,
      SISTEMA_TEMPO_STATUS: null,
    },
    rules: rules,
    valid: false,
  }),
  created() {
    this.formData.EMAILS_ADMIN_MASTER = this.config[0].valor;
    this.formData.SETORES_MESA_IC = this.config[1].valor;
    this.formData.SETORES_MESA_TREINAMENTO = this.config[2].valor;
    this.formData.SETORES_APROVADORES = this.config[3].valor;
    this.formData.SISTEMA_TEMPO_STATUS = this.config[4].valor;
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    send() {
      if (this.formValid) this.$emit("send", this.formData);
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style></style>
